import { Text } from '../text/Text'
import styles from './Registration.module.scss'

export const Registration = () => (
  <div className={styles.root}>
    <Text>
      <h2>2024/25 Summer season has arrived!!!</h2>
      <p>Everton Districts Cricket Club is currently recruiting for the upcoming 2024/25 Summer Season.</p>
      <p>
        Commencing in early September we will be fielding multiple senior sides across the Queensland Sub Districts Cricket Association
        (QSDCA) Saturday competition and we are inviting expressions of interest for new members who are keen to join us this year.
      </p>
      <p>
        If you're interested please let us know by completing the{' '}
        <a href="https://form.jotform.com/241927790295872" target="_blank">
          Expression of Interest form
        </a>
        .
      </p>
      <p>
        Senior training will be on Tuesday evenings from 5:30pm with all current, returning and new members invited to attend where possible
        commencing 23 July.
      </p>

      <p>
        For Juniors, registrations for children from ages 9 to 14 are now open by{' '}
        <a href="https://www.playhq.com/cricket-australia/register/35ed2e" target="_blank">
          clicking here
        </a>
        . Further information regarding training and season details will be released as Brisbane North Junior Cricket Association
        Inc.(BNJCA) competition and season information is finalised, you can subscribe to our{' '}
        <a href="https://mailchi.mp/02ff039a52d3/edcc-junior-cricketing-mailing-list" target="_blank">
          Junior club mailing list
        </a>{' '}
        to keep updated.
      </p>
      <p>
        For more information, please <a href="mailto:EvertonDCC@outlook.com">send us an email</a> or visit the club web page:
      </p>
      <a href="https://www.evertondistrictcc.com/">Everton Districts Cricket Club</a>
      <a href="https://www.playhq.com/cricket-australia/org/everton-districts-cricket-club/a76b4c38" target="_blank">
        PlayHQ Registration
      </a>
      <p>We look forward to welcoming you to the club soon!</p>
    </Text>
  </div>
)
