import { Text } from '../../text/Text'
import styles from '../PolicyContent.module.scss'

export const DisputeResolution = () => (
  <div className={styles.root}>
    <Text>
      <h2>Dispute Resolution</h2>
    </Text>
    <Text>
      <h3>Disputes Between Teams</h3>
      <p>
        Disputes between teams on game days normally arise from rule interpretation or on field sledging / behaviour not in the spirit of
        the game.
        <br />
        At all times the coach / manager is to communicate with the coach / manager of the opposing team prior to the commencement of the
        first day of play. This is to clarify how the game will be played and to develop a relationship from the outset.
      </p>
      <p>Coaches / managers are to keep a rule book in their kit at all times.</p>
      <p>
        If a coach / manager considers the other team to be sledging or not playing within the true spirit of the game they are to have a
        quiet / confidential chat to the opposing coach / manager at the first drinks available drinks break. Outlining specific examples,
        in a non-aggressive manner, is mandatory. It is suggested a solution also be provided at this time. This could be the behaviour of
        the players or the spectators the same rules apply.
      </p>
      <p>
        Failure to resolve the dispute on game day (respective coaches / managers) requires the incident / dispute to be bought to the
        attention of the Everton Districts Cricket Club management committee President, Vice President, Secretary and Treasurer, in
        consultation with the full committee. The appropriate person will take the incident / dispute up with their counterpart within the
        offending club in an attempt to resolve the incident / dispute. The BNJCA management committee will also be notified.
      </p>
      <p>
        Failure to resolve the incident / dispute at club level will require the intervention of the BNJCA management committee who may wish
        to:
      </p>
      <ul>
        <li>Mediate between the parties and the respective clubs; or</li>
        <li>Bring the incident / dispute to the Queensland Junior Cricket governing body.</li>
      </ul>
      <p>The most important point in this section is for coaches / managers to make every endeavour to:</p>
      <ul>
        <li>Develop a relationship with the opposing coach / manager prior to the commencement of the first day&#8217;s play;</li>
        <li>Communication of team and club philosophy is suggested; and</li>
        <li>
          Discuss the observation of an incident / issue at the first available drinks breaks in a non-aggressive, non-threatening and
          confidential manner is mandatory.
        </li>
      </ul>
    </Text>
    <hr />
    <Text>
      <h3>Disputes Between Team Members</h3>
      <p>This may occur between players or parents. At all times the team coach / manager is to adjudicate between the parties.</p>
      <p>
        If it is a behavioural problem the issue / dispute is to be bought to the attention of the Everton Districts Cricket Club management
        committee President, Vice President, Secretary and Treasurer, in consultation with the full committee, who will discuss the issue /
        dispute directly with the offending party / parties.
      </p>
      <p>
        If the incident / dispute is serious in nature (i.e. physical or verbal assault / abuse) the discussion will be followed up in
        writing.
      </p>
      <p>
        The process and outcome will remain confidential to the Everton Districts Cricket Club management committee. Continuation or
        repetition of the incident / dispute may result in the player or family being requested to leave the club. At all times these
        incidents / disputes are to be written into an incident report and lodged with the club Secretary who will retain the reports on
        file.
      </p>
    </Text>
    <hr />
    <Text>
      <h3>Disputes Over Fees or Payments</h3>
      <p>
        All issues / disputes of this nature are to be dealt with by the Everton Districts Cricket Club management committee President, Vice
        President, Secretary and Treasurer, in consultation with the full committee.
      </p>
      <p>Fees will be set at a general meeting of the Everton Districts Cricket Club prior to the commencement of the new season.</p>
      <p>
        Club Treasurer automatically assumes responsibility for ensuring all players are fully paid or have arrangements in place to provide
        periodic payments.
      </p>
      <p>Club Treasurer is to provide a report at finalisation of the incident / dispute to be held as a record by the club Secretary.</p>
    </Text>
    <hr />
    <Text>
      <h3>Disputes with Club Administration</h3>
      <p>
        Any disputes regarding club administration are to be dealt with by the Everton Districts Cricket Club management committee
        President, Vice President, Secretary and Treasurer, in consultation with the full committee.
      </p>
      <p>Complaining party is to provide their concerns in writing and will be invited to discuss these with the management committee.</p>
      <p>
        Full committee of the Everton Districts Cricket Club will decide on the appropriate resolution, which may require a motion at the
        next AGM.
      </p>
    </Text>
    <hr />
    <Text>
      <h3>Disputes with Brisbane City Council</h3>
      <p>
        Any dispute of this nature is to be discussed with the full committee of the Everton Districts Cricket Club will decide on the
        appropriate resolution.
      </p>
      <p>At all times the club is to administer and manager within the boundaries of the registered lease.</p>
      <p>At all times these disputes must be written up by the club Secretary who will retain the document as a record.</p>
    </Text>
    <hr />
    <Text>
      <h3>Disputes within the Committee</h3>
      <p>
        All committee members are to giver equal time putting their argument for or against a particular topic, issue or policy direction.
      </p>
      <p>
        The Committee is to vote on an issue / dispute with majority decision to be final. All committee members agree to abide by the
        decision.
      </p>
      <p>
        Each committee member is responsible for ensuring their position is stated clearly and in person so there is no confusion within the
        group.
      </p>
      <p>The Committee is governed by the following club mission, vision and positioning statements.</p>
    </Text>
    <hr />
    <Text>
      <h3>Our Vision</h3>
      <p>To provide facilities for playing, competing in, training for and otherwise participating in the game of cricket.</p>
    </Text>
    <hr />
    <Text>
      <h3>Our Mission</h3>
      <p>
        To provide facilities for the meeting, refreshment and accommodation of members and persons interested in the game of cricket and
        opportunities for spectators to view the said game.
      </p>
    </Text>
    <hr />
    <Text>
      <h3>Our Positioning Statement</h3>
      <p>
        To promote and encourage the game of cricket in the Everton Park district. The Committee is also governed by the requirements under
        incorporation administered by the Office of Fair Trading.
      </p>
    </Text>
  </div>
)
