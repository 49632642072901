import styles from './Contact.module.scss'
import { GrMail } from 'react-icons/gr'
import { FaPhoneAlt } from 'react-icons/fa'

export const Contact = () => (
  <div className={styles.root}>
    <div>
      <h3>Physical Address</h3>
      <p>Corner Kenna Street & Whites Road, West Chermside, Queensland, 4032</p>
      <h3>Postal Address</h3>
      <p>P.O. Box 5501, Stafford Heights, Queensland, 4053</p>
      <h3>Contacts</h3>
      <ul>
        <ContactItem title="Juniors" name="Ronnel Maharaj" mailto="ronnelrahulmaharaj@gmail.com" />
        <ContactItem title="Secretary / Seniors" name="Jake Rosen" mailto="EvertonDCC@outlook.com" />
      </ul>
    </div>
    <iframe
      className={styles.map}
      loading="lazy"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3542.8897355409104!2d153.0071150156858!3d-27.37915881941467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b9157fead8c2793%3A0x7fa1636c6b77819e!2sEverton%20Districts%20Cricket%20Club!5e0!3m2!1sen!2sau!4v1625941480302!5m2!1sen!2sau"
    ></iframe>
  </div>
)

const ContactItem = (props: { title: string; name: string | null; mailto?: string; phone?: string }) => (
  <li>
    <h4>{props.title}</h4>
    <span>{props.name || '-'}</span>
    {(!!props.mailto || !!props.phone) && (
      <div className={styles.contact}>
        {!!props.mailto && (
          <a href={`mailto:${props.mailto}`}>
            <GrMail /> Email
          </a>
        )}
        {!!props.phone && (
          <a href={`tel:${props.phone}`}>
            <FaPhoneAlt /> Phone
          </a>
        )}
      </div>
    )}
  </li>
)
