import { Text } from '../../text/Text'
import styles from '../PolicyContent.module.scss'

export const MembershipPolicy = () => (
  <div className={styles.root}>
    <Text>
      <h2>Fees / Senior Membership Policy</h2>
    </Text>
    <Text>
      <h3>Everton Districts Cricket Club summer season 2024/25 senior membership policy</h3>
      <ul>
        <li>
          <a
            href="https://edcccoldstorage.blob.core.windows.net/membership-policies/EDCC Fee's Policy - 2024-25 Summer Season (Final).pdf"
            target="_blank"
            download
          >
            Summer Season 2024/25 Senior Membership Policy
          </a>
        </li>
      </ul>
    </Text>
  </div>
)
