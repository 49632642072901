import CricketBlast from '../../images/cricket-blast.png'
import JuniorCricket from '../../images/junior-cricket.png'
import SeniorCricket from '../../images/senior-cricket.png'
import styles from './Play.module.scss'

export const Play = () => (
  <div className={styles.root}>
    <PlayItem
      title="Cricket Blast"
      ages="5-10"
      imageSrc={CricketBlast}
      moreInformationUrl="https://play.cricket.com.au/cricket-blast"
    />
    <PlayItem
      title="Junior Cricket"
      ages="9-18"
      imageSrc={JuniorCricket}
      moreInformationUrl="https://play.cricket.com.au/kids/club-cricket"
    />
    <PlayItem
      title="Senior Cricket"
      ages="18+"
      imageSrc={SeniorCricket}
      moreInformationUrl="https://play.cricket.com.au/adults/club-cricket"
    />
  </div>
)

const PlayItem = (props: { title: string; ages: string; imageSrc: string; moreInformationUrl: string }) => (
  <div>
    <h2>{props.title}</h2>
    <span>Ages {props.ages}</span>
    <img src={props.imageSrc} alt="" />
    <a
      className={styles.button}
      href="https://play.cricket.com.au/club/everton-districts-cricket-club/8f0ad927-87d8-eb11-a7ad-2818780da0cc"
      target="_blank"
      rel="noreferrer"
    >
      Register
    </a>
    <a className={styles.moreInformation} href={props.moreInformationUrl} target="_blank" rel="noreferrer">
      More information
    </a>
  </div>
)
