import styles from './Gallery.module.scss'
import JimNiebling from '../../images/jimniebling.jpg'
import Sheds from '../../images/sheds.jpg'
import Juniors from '../../images/juniors.jpg'
import a1 from '../../images/a1.jpg'
import a2 from '../../images/a2.jpg'
import a3 from '../../images/a3.jpg'
import a4 from '../../images/a4.jpg'
import a5 from '../../images/a5.jpg'
import a6 from '../../images/a6.jpg'
import a7 from '../../images/a7.jpg'
import a8 from '../../images/a8.jpg'
import a9 from '../../images/a9.jpg'
import cn from 'classnames'
import LightGallery from 'lightgallery/react'
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'
import 'lightgallery/css/lg-thumbnail.css'
import lgThumbnail from 'lightgallery/plugins/thumbnail'

const images = [JimNiebling, Sheds, Juniors, a6, a1, a2, a3, a4, a5, a7, a9, a8]

export const Gallery = (props: { preview?: boolean }) => (
  <LightGallery elementClassNames={cn(styles.root, { [styles.inline]: props.preview })} plugins={[lgThumbnail]}>
    {images.map((image, i) => (
      <a href={image} key={i}>
        <img src={image} />
      </a>
    ))}
  </LightGallery>
)
