import { Text } from "../text/Text";

export const Welcome = () => (
  <Text>
    <h2>Welcome</h2>
    <p>Welcome to the home of Everton Districts Cricket Club.</p>
    <p>
      We are a fun and social club for juniors and seniors located in West Chermside, just off Hamilton Rd. Our teams play a fun brand of
      cricket and perform well across all ages.
    </p>
    <p>
    We have been catering for junior and senior cricketers for 50 years, and have one of the most picturesque fields and best facilities amongst community cricket clubs.
    </p>
    <p>It&apos;s a great place to learn cricket and meet new mates.</p>
  </Text>
)
