import styles from './Socials.module.scss'
import { TiSocialFacebook } from 'react-icons/ti'
import { TiSocialTwitter } from 'react-icons/ti'

export const Socials = () => (
  <div className={styles.root}>
    <a href="https://twitter.com/@Evertoncomets" target="_blank" rel="noreferrer">
      <TiSocialTwitter />
    </a>
    <a href="https://www.facebook.com/EvertonDistrictsCricketClub" target="_blank" rel="noreferrer">
      <TiSocialFacebook />
    </a>
  </div>
)
