import { Text } from '../text/Text'
import styles from './Facilities.module.scss'

export const Facilities = (props: { setModalContent: (element: JSX.Element | undefined) => void }) => (
  <Text>
    <h2>Facilities</h2>
    <p>
      With over 7.2 hectares of land, we have two turf fields and one synthetic field, which our junior and senior players get to play on.
    </p>
    <p>
      We have three a fully illuminated cricket nets that are available to our players for training. Our training equipment includes a
      bowling machine that is also available to our players for their use.
    </p>
    <p>Above all, we have a great club house that has:</p>
    <ul>
      <li>Change rooms for the home and away teams</li>
      <li>A kitchen where hot food can be made available</li>
      <li>Great barbeque facilities</li>
      <li>A licensed bar for senior players and patrons to enjoy a cold one at the end of the day</li>
    </ul>
    <p>
      Read more about the club's history{' '}
      <u className={styles.link} onClick={() => props.setModalContent(History())}>
        here.
      </u>
    </p>
  </Text>
)

const History = () => (
  <div className={styles.content}>
    <Text>
      <h3>History of everton Districts Cricket Club</h3>
      <p>
        Until the 1974/75 season, Everton Districts Cricket Club was a sub-committee of the Everton Districts Australian Rules Football Club
        and played out of fields at Mott Street, Enoggera. In 1974, the cricketers broke away and became Everton Districts Cricket Club,
        with a later name change to Everton Districts Cricket Club Inc. From the late 1970’s to 1980, the club was the tenant of a piece of
        Brisbane City Council land in Blaker Road, Oxford Park. However, development restrictions were placed on the club, and as the land
        was re-zoned to open space, efforts to construct a clubhouse were rejected by the Council.
      </p>
      <p>
        From mid 1980, negotiations took place with the Brisbane City Council to acquire a piece of land at West Chermside, and after three
        years of discussion, approval was given to commence the development of an inactive, vermin-infested rubbish tip. Rats the size of
        small cats, spiders, snakes and a variety of other infestations were finally buried or driven from the area.
      </p>
      <p>
        In November 1984, the first push from a bull dozer signaled what would become a week-end ritual for the next five years. Four people
        began to achieve a dram of “turf wickets for junior cricketers”. John Manson (the whole complex is the John Manson Sports Complex),
        Jim Neibling (the Jim Neibling Oval is the western most oval), Peter Gallagher (the Peter Gallagher Oval is directly in front of the
        clubhouse), and Bill Willett (the clubhouse is the William &#8220;Bill&#8221; Willett Pavilion), along with a number of other
        stalwarts, have been responsible for the development of the complex that is now being enjoyed by hundreds of past and present
        members.
      </p>
      <p>
        An estimated 1,000,000 yards of fill has been buried and leveled by the club. Approximately $150,000 of the club’s funds was used
        for the development of the fields and clubhouse. It was estimated by both Council and private companies that to develop the fields
        commercially using the same materials would have cost around one million dollars. Some areas of the complex have been lifted over
        sixty feet. And all of this was done by club volunteers.
      </p>
      <p>
        It therefore goes without saying that members of Everton Districts Cricket Club must appreciate and protect the facilities we have,
        as it will be a long time before anything like this will happen again.
      </p>
      <p>
        The fields are leased through the Brisbane City Council and are presently used by approximately 230 junior and senior club members
        for training and games.
      </p>
      <p>
        Everton Districts is also home for Metropolitan North School Sports and host their representative cricket teams from Brisbane North
        Primary and Secondary schools. Metropolitan North uses the complex for training, selection trials and matches. The fields are
        further used by primary and secondary schools for inter-school cricket on Wednesday and Friday afternoons.
      </p>
      <p>
        Everton Districts Cricket Club relies greatly on volunteer support from the players, patrons and members of the local community in
        order to help maintain and further develop the clubs facilities. Our intention is to ensure that the club has a bright future, both
        as a cricketing venue and a place for the whole family.
      </p>
    </Text>
  </div>
)
