import { ContentWrapper } from '../components/contentWrapper/ContentWrapper'
import { Gallery } from '../components/gallery/Gallery'
import { ScrollToTopButton } from '../components/scrollToTopButton/ScrollToTopButton'

export const GalleryView = () => (
  <>
    <ContentWrapper>
      <Gallery />
    </ContentWrapper>
    <ScrollToTopButton mode="dark" />
  </>
)
