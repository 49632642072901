import { Text } from '../../text/Text'
import styles from '../PolicyContent.module.scss'

export const CoachBehaviour = () => (
  <div className={styles.root}>
    <Text>
      <h2>Coaches Code of Behaviour</h2>
    </Text>
    <Text>
      <h3>What is expected of a coach of an Everton Districts team?</h3>
      <ul>
        <li>
          Your primary goal is to ensure that all the children in your team have fun playing cricket. Winning is not an essential ingredient
          for having fun.
        </li>
        <li>Teach your players to follow the rules.</li>
        <li>Be reasonable in your demands on young players&#8217; time, energy, and enthusiasm.</li>
        <li>DO NOT ridicule or yell at a child for making a mistake or losing.</li>
        <li>
          Develop team respect for the ability of opponents. Encourage the team to applaud good play by your team and by members of the
          opposing team.
        </li>
        <li>Develop team respect for the judgement of officials and opposing coaches.</li>
        <li>Avoid overplaying the talented players. Every player deserves equal time and encouragement.</li>
        <li>Ensure that equipment and facilities meet safety standards.</li>
      </ul>
    </Text>
  </div>
)
