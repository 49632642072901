import { Text } from '../text/Text'
import styles from './Links.module.scss'
import { AiOutlineBoxPlot, AiOutlineOrderedList, AiOutlineTrophy, AiOutlineDeleteRow } from 'react-icons/ai'
import { TbChartInfographic } from 'react-icons/tb'
import { MdOutlineQueryStats } from 'react-icons/md'

export const Links = () => (
  <Text>
    <h2>MyCricket</h2>
    <ul className={styles.links}>
      <li>
        <a
          href="https://mycricket.cricket.com.au/common/pages/public/rv/clubresults.aspx?entityid=1473&id=CLR"
          target="_blank"
          rel="noreferrer"
        >
          <AiOutlineBoxPlot /> Latest match results
        </a>
      </li>
      <li>
        <a
          href="https://mycricket.cricket.com.au/common/pages/public/rv/draw.aspx?entityid=1473&id=RVFIXTURE"
          target="_blank"
          rel="noreferrer"
        >
          <AiOutlineDeleteRow /> Season fixtures
        </a>
      </li>
      <li>
        <a
          href="https://mycricket.cricket.com.au/common/pages/public/rv/ladder.aspx?mode=0&id=LAD&entityid=1473"
          target="_blank"
          rel="noreferrer"
        >
          <AiOutlineOrderedList /> Ladders
        </a>
      </li>
      <li>
        <a
          href="https://mycricket.cricket.com.au/common/pages/public/rv/cricket/battingav.aspx?entityid=1473&id=CBA"
          target="_blank"
          rel="noreferrer"
        >
          <MdOutlineQueryStats /> Batting averages
        </a>
      </li>
      <li>
        <a
          href="https://mycricket.cricket.com.au/common/pages/public/rv/cricket/bowlingav.aspx?entityid=1473&id=CBO"
          target="_blank"
          rel="noreferrer"
        >
          <TbChartInfographic /> Bowling averages
        </a>
      </li>
      <li>
        <a
          href="https://mycricket.cricket.com.au/common/pages/public/rv/ChampionPlayer.aspx?entityid=1473&id=CCH"
          target="_blank"
          rel="noreferrer"
        >
          <AiOutlineTrophy /> Champion player
        </a>
      </li>
    </ul>
  </Text>
)
