import { Text } from '../../text/Text'
import styles from '../PolicyContent.module.scss'

export const ParentBehaviour = () => (
  <div className={styles.root}>
    <Text>
      <h2>Parents Code of Behaviour</h2>
    </Text>
    <Text>
      <h3>Some simple hints to help your child to learn to be &#8216;a good sport&#8217;.</h3>
      <ul>
        <li>Remember &#8211; Children are involved in organised sports for their enjoyment, not that of the parents.</li>
        <li>Encourage your child always to play by the rules.</li>
        <li>
          Teach your child that honest effort is as important as victory, so that the result of each game is accepted without undue
          disappointment.
        </li>
        <li>Turn defeat into victory by helping your child work towards skill improvement and good sportsmanship.</li>
        <li>Never ridicule or yell at your child for making a mistake or losing a competition.</li>
        <li>Remember that children learn best by example. Applaud good play by your team and by members of the opposing team.</li>
        <li>Do not publicly question the officials&#8217; judgement and never their honesty.</li>
        <li>Support all efforts to remove verbal and physical abuse from children&#8217;s sporting activities.</li>
        <li>
          Recognise the value and importance of volunteer coaches. They give of their time and resources to provide recreational activities
          for your child.
        </li>
      </ul>
    </Text>
  </div>
)
