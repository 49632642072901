import { useEffect, useRef, useState } from 'react'
import Logo from '../../images/logo-blue.png'
import styles from './Header.module.scss'
import cn from 'classnames'
import { Link, useLocation } from 'react-router-dom'

export const Header = () => {
  const [isFixed, setIsFixed] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)
  const location = useLocation()

  useEffect(() => {
    const observer = new IntersectionObserver(([e]) => setIsFixed(e.intersectionRatio < 1), { threshold: [1] })
    ref.current && observer.observe(ref.current)

    return () => {
      ref.current && observer.unobserve(ref.current)
    }
  }, [ref])

  return (
    <>
      <header ref={ref} className={cn(styles.root, { [styles.fixed]: isFixed })}>
        <h1>Everton</h1>
        <img className={styles.logo} src={Logo} alt="Everton Districts Cricket Club Logo" />
        <h1>Districts</h1>
        <nav className={cn(styles.navigation, { [styles.fixed]: isFixed })}>
          <Link to="/" className={cn({[styles.active]: location.pathname === '/'})}>Home</Link>
          <Link to="/players" className={cn({[styles.active]: location.pathname === '/players'})}>Players</Link>
          <Link to="/gallery" className={cn({[styles.active]: location.pathname === '/gallery'})}>Gallery</Link>
        </nav>
      </header>
    </>
  )
}
