import { useState } from 'react'
import { GrClose } from 'react-icons/gr'
import ReactModal from 'react-modal'
import { ContentWrapper } from '../components/contentWrapper/ContentWrapper'
import { Links } from '../components/links/Links'
import { Policies } from '../components/policies/Policies'
import { ScrollToTopButton } from '../components/scrollToTopButton/ScrollToTopButton'
import { Volunteers } from '../components/volunteers/Volunteers'
import styles from './Views.module.scss'
import noScroll from 'no-scroll'

export const PlayersView = () => {
  const [modalContent, setModalContent] = useState<JSX.Element | undefined>(undefined)

  return (
    <>
      <ContentWrapper>
        <Links />
        <Policies setModalContent={setModalContent} />
        <Volunteers setModalContent={setModalContent} />
      </ContentWrapper>
      <ScrollToTopButton mode="dark" />
      <ReactModal
        ariaHideApp={false}
        className={styles.modal}
        overlayClassName={styles.overlay}
        isOpen={!!modalContent}
        onRequestClose={() => setModalContent(undefined)}
        onAfterOpen={() => noScroll.on()}
        onAfterClose={() => noScroll.off()}
      >
        <ContentWrapper>
          <button onClick={() => setModalContent(undefined)}>
            <GrClose />
          </button>
          {modalContent}
        </ContentWrapper>
      </ReactModal>
    </>
  )
}
