import { Text } from '../../text/Text'
import styles from '../PolicyContent.module.scss'

export const ChildSafety = () => (
  <div className={styles.root}>
    <Text>
      <h2>Child Safety</h2>
    </Text>
    <Text>
      <h3>Child safety policy documents and information regarding child safety at Everton Districts Cricket Club</h3>
      <ul>
        <li>
          <a
            href="https://edcccoldstorage.blob.core.windows.net/child-safety/AC Looking After Our Kids Code of Behaviour for Affiliated Associations Clubs  Indoor Centres 2022 U.pdf"
            target="_blank"
            download
          >
            Looking After Our Kids
          </a>
        </li>
        <li>
          <a
            href="https://edcccoldstorage.blob.core.windows.net/child-safety/AC Commitment Statement for Safegarding Children  Young People 2022.pdf"
            target="_blank"
            download
          >
            Commitment Statement for Safeguarding Children and Young People
          </a>
        </li>
        <li>
          <a
            href="https://edcccoldstorage.blob.core.windows.net/child-safety/AC Policy for Safeguarding Children  Young People 2022 1.pdf"
            target="_blank"
            download
          >
            Policy for Safeguarding Children and Young People
          </a>
        </li>
      </ul>
    </Text>
  </div>
)
