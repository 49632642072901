import styles from './ScrollToTopButton.module.scss'
import cn from 'classnames'
import { useEffect, useLayoutEffect, useState } from 'react'

const heightOffsetBeforeRendering = 200

export const ScrollToTopButton = (props: { mode: 'light' | 'dark' }) => {
  const [pageExceedsViewport, setPageExceedsViewport] = useState(false)

  const updatePageExceedsViewport = () =>
    setPageExceedsViewport(document.body.scrollHeight - heightOffsetBeforeRendering > (window.visualViewport?.height || 0))

  useLayoutEffect(updatePageExceedsViewport, [])
  useEffect(() => {
    window.addEventListener('resize', updatePageExceedsViewport)
    return () => window.removeEventListener('resize', updatePageExceedsViewport)
  }, [])

  return pageExceedsViewport ? (
    <div className={cn(styles.root, { [styles.light]: props.mode === 'light' })}>
      <button className={styles.button} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
        Back to top
      </button>
    </div>
  ) : null
}
