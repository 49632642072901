import { Text } from '../../text/Text'
import styles from '../PolicyContent.module.scss'

export const CodeOfConduct = () => (
  <div className={styles.root}>
    <Text>
      <h2>Code of Conduct</h2>
    </Text>
    <Text>
      <h3>Introduction</h3>
      <p>
        The Everton Districts Cricket Club (EDCC) is an organisation which values behaviour that is in compliance with the laws, regulations
        and general expectations of Cricket Australia, Queensland Cricket and the wider community to which it renders its services and
        provides its function.
      </p>
      <p>
        Traditionally, cricket has been the one sport to maintain and promote the highest level of conduct and sportsmanship. Whilst still a
        competitive sport, it&#8217;s continued strength relies on the preparedness to play within the spirit of the game as defined by
        Cricket Australia&#8217;s Spirit of Cricket expectations.
      </p>
      <p>
        These expectations are available in the form of the Queensland Cricket Handbook and can be obtained by visiting the Queensland
        Cricket website at www.qldcricket.com.au.
      </p>
      <p>
        The club does not wish to reduce the level of competitiveness or intensity with which members play the game however, it is expected
        that members in no way engage in conduct which may negatively impact on the reputation of the EDCC.
      </p>
      <p>
        Where a member is deemed to have engaged in conduct that has &#8220;brought the club in to disrepute&#8221; the executive committee
        may, at their discretion impose a penalty upon that player as outlined later in this document.
      </p>
      <p>
        <em>*Note that this is a living document and may be updated by the executive committee at any times</em>
      </p>
    </Text>
    <hr />
    <Text>
      <h3>Purpose</h3>
      <p>
        To meet agreed principles to ensure standard requirements of the club are known and met. It also guarantees club safety, efficiency
        and sociability.
      </p>
    </Text>
    <hr />
    <Text>
      <h3>Expected Behaviour</h3>
      <h4>On-field Behaviour</h4>
      <p>
        Traditionally, cricket has been the one sport to maintain and promote the highest level of conduct and sportsmanship. Whilst still a
        competitive sport, it&#8217;s continued strength relies on the preparedness to play within the spirit of the game as defined by
        Cricket Australia&#8217;s Spirit of Cricket expectations.
      </p>
      <p>
        These expectations are available in the form of the Queensland Cricket Handbook and can be obtained by visiting the Queensland
        Cricket website at www.qldcricket.com.au.
      </p>
      <p>
        The club does not wish to reduce the level of competitiveness or intensity with which members play the game however, it is expected
        that members in no way engage in conduct which may negatively impact on the reputation of the EDCC.
      </p>
      <p>
        Where a member is deemed to have engaged in conduct that has &#8220;brought the club in to disrepute&#8221; the executive committee
        may, at their discretion impose a penalty upon that player as outlined later in this document.
      </p>
      <h4>Off-field Behaviour</h4>
      <p>
        The expected behaviours of members are not limited to that of players who are participating in an official club fixture. The conduct
        of members is expected to be at the highest level at any time they are representing or may be seen by members of the community to be
        representing the EDCC. Where any members engage in conduct which is seen to have &#8220;brought the club in to disrepute&#8221; the
        executive committee may, at their discretion impose a penalty upon that member as outlined later in this document.
      </p>
    </Text>
    <hr />
    <Text>
      <h3>Respect for Property</h3>
      <p>All members are expected to show respect for property when representing EDCC. Examples of this include:</p>
      <ul>
        <li>Property of the EDCC such as change rooms, club house facilities and public areas</li>
        <li>Property of other organisations providing facilities such as change rooms and club house facilities</li>
      </ul>
      <p>
        Where members are utilising facilities and property it is expected that said property is to be maintained in the conditions it was
        prior to use.
      </p>
    </Text>
    <hr />
    <Text>
      <h3>Alcohol and Drugs</h3>
      <p>
        The EDCC supports efforts to improve awareness around the adverse effects of over-consumption of alcohol and encourages its members
        to drink responsibly. It is expected that EDCC members will monitor their own behaviour when consuming alcohol at any club function,
        or function where that members conduct has the ability to impact on the reputation of the EDCC.
      </p>
      <p>
        Where the consumption of alcohol is involved or contributes to un-savoury behaviour by any of its members, that has the potential to
        damage the public reputations of the club, the EDCC reserves the right to impose penalties against that member or members, as deemed
        necessary by the executive committee.
      </p>
      <p>
        Where members are consuming alcohol during a club function or on the club premises, the EDCC reserves the right to refuse sale to
        persons deemed to be unduly intoxicated. No alcohol shall be consumed by a player during the hours of play.
      </p>
      <p>
        The EDCC in no way condones the use of illicit drugs; any members found to be in possession or under the influence whilst at the
        club, a club function or in any capacity as a club representative will at the discretion of the Executive Committee face a penalty
        outlined later in this document.
      </p>
    </Text>
    <hr />
    <Text>
      <h3>Respect for Persons</h3>
      <p>
        Members of the EDCC are expected to show due respect for all other individuals with the club and externally to the club when
        representing the EDCC. Examples of behaviour that will not be tolerated by the club include:
      </p>
      <ul>
        <li>Assault against another individual (including sexual assault)</li>
        <li>Harassment (including sexual)</li>
        <li>Racial vilification in any form and</li>
        <li>Defaming remarks of any sort against any individual</li>
      </ul>
    </Text>
    <hr />
    <Text>
      <h3>Disciplinary Policy</h3>
      <p>
        Where a member of the EDCC is found to be in breach of this code of conduct that member may be required to provide an explanation in
        writing to the executive committee, and in some cases asked to appear in person before a disciplinary team comprising three (3)
        members of the executive committee.
      </p>
      <p>
        The EDCC may impose such penalty as it sees fit based upon the breach of this code-of-conduct. Members should also be aware that
        where they are charged by, or found to be in breach of Qld Cricket and Cricket Australia regulations that member may face additional
        disciplinary requirements from the EDCC executive committee.
      </p>
    </Text>
    <hr />
    <Text>
      <h3>Grievances</h3>
      <p>
        For any grievances, the matter should be referred to the team captain. If it is unresolved the next level of referral will be the
        Executive Committee.
      </p>
    </Text>
    <hr />
    <Text>
      <h3>Registration / Game Fees</h3>
      <p>Registration fees are due by the predetermined date for each season. This date will be publicised and available to all.</p>
      <p>Players will not be placed in teams unless a deposit for the registration fee is paid prior to the season start.</p>
      <p>
        Fill in players will be required to pay game fees for each day of play and will not be allowed to take the field until these fees
        are paid prior to the start of the days play.
      </p>
      <p>
        It is the players&#8217; responsibility to contact the club or team captain before the Wednesday preceding the game to confirm
        commitment to play. This may include a confirmation to play directly after the previous game. Failure to meet this commitment to
        play will attract a penalty and may take the form of match suspension.
      </p>
    </Text>
  </div>
)
