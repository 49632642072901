import { CodeOfConduct } from './policyContent/CodeOfConduct'
import styles from './Policies.module.scss'
import { PlayerBehaviour } from './policyContent/PlayerBehaviour'
import { ParentBehaviour } from './policyContent/ParentsBehaviour'
import { CoachBehaviour } from './policyContent/CoachBehaviour'
import { DisputeResolution } from './policyContent/DisputeResolution'
import { ChildSafety } from './policyContent/ChildSafety'
import { MembershipPolicy } from './policyContent/MembershipPolicy'

export const Policies = (props: { setModalContent: (element: JSX.Element | undefined) => void }) => (
  <div className={styles.root}>
    <h2>Policies</h2>
    <ul className={styles.policyItems}>
      <li onClick={() => props.setModalContent(MembershipPolicy())}>Fees / Senior Membership Policy</li>
      <li onClick={() => props.setModalContent(CodeOfConduct())}>Code of Conduct</li>
      <li onClick={() => props.setModalContent(PlayerBehaviour())}>Players Code of Behaviour</li>
      <li onClick={() => props.setModalContent(ParentBehaviour())}>Parents Code of Behaviour</li>
      <li onClick={() => props.setModalContent(CoachBehaviour())}>Coaches Code of Behaviour</li>
      <li onClick={() => props.setModalContent(DisputeResolution())}>Dispute Resolution</li>
      <li onClick={() => props.setModalContent(ChildSafety())}>Child Safety</li>
    </ul>
  </div>
)
