import styles from './App.module.scss'
import { LandingView } from './views/LandingView'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { GalleryView } from './views/GalleryView'
import { Header } from './components/header/Header'
import { PlayersView } from './views/PlayersView'
import { Socials } from './components/socials/Socials'

export const App = () => (
  <div className={styles.root}>
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<LandingView />} />
        <Route path="/players" element={<PlayersView />} />
        <Route path="/gallery" element={<GalleryView />} />
      </Routes>
      <Socials />
    </Router>
  </div>
)
