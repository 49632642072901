import { Text } from '../text/Text'
import styles from './Volunteers.module.scss'

export const Volunteers = (props: { setModalContent: (element: JSX.Element | undefined) => void }) => (
  <div className={styles.root}>
    <Text>
      <h2>Volunteers</h2>
      <p>
        There are a range of ways you can contribute to the running of the club. Volunteers are always welcomed and greatly appreciated.
      </p>
      <ul>
        <li onClick={() => props.setModalContent(JuniorCoach())}>Volunteer as a Junior Coach</li>
        <li onClick={() => props.setModalContent(Canteen())}>Volunteer for the Canteen</li>
        <li onClick={() => props.setModalContent(GroundMaintenance())}>Volunteer for Ground Maintenance</li>
        <li onClick={() => props.setModalContent(AllRounder())}>Volunteer as an All Rounder</li>
        <li onClick={() => props.setModalContent(Committee())}>Volunteer on the Committee</li>
      </ul>
    </Text>
  </div>
)

const JuniorCoach = () => (
  <div className={styles.content}>
    <Text>
      <h3>Volunteer as a Junior Coach</h3>
      <p>
        Coaching a junior cricket team is a great opportunity to support the club and play a role in guiding young players in the
        development of their skills and their team spirit.
      </p>
      <p>
        You don&#8217;t need to be an accredited coach to get started as a junior coach with the club, but we recommend you complete your
        Level 0 Accreditation with Queensland Cricket. You can do that online and for free. From there you can choose to progress to further
        coaching accreditation levels.
      </p>
      <p>
        <a href="https://www.qldcricket.com.au/get-involved/coaching/coaching-accreditation-courses" target="_top" rel="noopener">
          Click here to find out more about Queensland Cricket Coaching Accreditation
        </a>
      </p>
      <p>
        More important than accreditation, especially when coaching juniors, is a love of the game and a focus on developing the
        players&#8217; love of cricket and the fun of playing a team sport.
      </p>
      <p>
        If you&#8217;re interested in coaching a team that, for example, your son or daughter is playing in, please contact us and
        we&#8217;ll be in touch.
      </p>
    </Text>
  </div>
)

const Canteen = () => (
  <div className={styles.content}>
    <Text>
      <h3>Volunteer for the Canteen</h3>
      <p>
        Each weekend the club canteen requires volunteers. In years gone past this has fallen on the shoulders of one or two people. To
        spread the load we now have a list of canteen volunteers who are happy to help out once or twice a season.
      </p>
      <p>
        It&#8217;s easy to help out in the canteen and it&#8217;s nice spending time around the club, chatting with the platters and taking
        in a bit of cricket (there&#8217;s a good view of the main wicket from the canteen).
      </p>
      <p>If you&#8217;d like to help out in the canteen, get in touch and let us know. Thanks for helping out!</p>
    </Text>
  </div>
)

const GroundMaintenance = () => (
  <div className={styles.content}>
    <Text>
      <h3>Volunteer for Ground Maintenance</h3>
      <p>Everton Districts has three cricket grounds, a turf wicket and synthetic cricket nets.</p>
      <p>
        Each week a dedicated group of club volunteers maintain the grounds with mowing, watering, maintaining and rolling the wicket, as
        well as a number of other activities such as covering and uncovering the centre wicket block.
      </p>
      <p>
        Without their tireless efforts, no cricket could be played. The club is extremely grateful for their contribution and also invites
        other players and parents of junior players to put up their hand to assist.
      </p>
      <p>
        For example, during summer if storms are about on a Friday, the more volunteers who can come to the ground to assist with covering
        the wicket, the faster it can be done and the less of a load it is on the core handful of volunteers.
      </p>
      <p>
        If you&#8217;d like to be added to a shortlist of volunteers who are happy to be called or texted when assistance is needed at the
        ground, please contact us and we&#8217;ll add you to the list.
      </p>
      <p>Many hands make light work!</p>
    </Text>
  </div>
)

const AllRounder = () => (
  <div className={styles.content}>
    <Text>
      <h3>Volunteer as an All Rounder</h3>
      <p>
        If you&#8217;re happy to help out the club in a wide range of ways as a volunteer, whether that&#8217;s helping with ground
        maintenance, odd jobs around the clubhouse, helping out on sign-on days by running the BBQ or helping on the desk, or on the
        canteen&#8230; get in touch and we&#8217;ll add you to the Volunteer All Rounder list.
      </p>
    </Text>
  </div>
)

const Committee = () => (
  <div className={styles.content}>
    <Text>
      <h3>Volunteer on the Committee</h3>
      <p>
        Keeping a club going is a team effort. Behind the scenes there are a number of people in different roles helping out each month to
        keep the wheels turning so that juniors, parents and senior players alike can enjoy their cricket. It&#8217;s satisfying to
        contribute to the club&#8217;s management, operations and development by being a committee member.
      </p>
      <p>
        Within the committee there are a range of roles including President, Treasurer, Secretary, Juniors, Seniors, Brisbane North Junior
        Cricket Association (BNJCA) Delegate, Warehouse Cricket Association Delegate, Website, Social Media, Community Involvement and so
        on.
      </p>
      <p>
        If you&#8217;d like to put your hand up as a future committee member, contact us and let us know, as we always need a group of
        current and prospective committee members to form a solid foundation for the management of the club.
      </p>
    </Text>
  </div>
)
