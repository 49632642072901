import { Text } from '../../text/Text'
import styles from '../PolicyContent.module.scss'

export const PlayerBehaviour = () => (
  <div className={styles.root}>
    <Text>
      <h2>Players Code of Behaviour</h2>
    </Text>
    <Text>
      <h3>What is expected of an Everton Districts player. Your primary goal is to have fun playing cricket.</h3>
      <ul>
        <li>Play by the rules.</li>
        <li>Judge yourself by the effort that you put in, not by the result of the game.</li>
        <li>Accept defeat and victory without undue joy or disappointment.</li>
        <li>DO NOT ridicule or yell at a team-mate for making a mistake.</li>
        <li>DO NOT sledge opposition players, you probably don&#8217;t like being sledged.</li>
        <li>Applaud good play by your team and by members of the opposing team.</li>
        <li>DO NOT publicly question an official&#8217;s judgement and never their honesty.</li>
        <li>
          Be a good sport: signal boundaries; declare when you haven&#8217;t taken a catch cleanly; don&#8217;t appeal when you know they
          are not out; etc.
        </li>
        <li>Respect your coach and manager.</li>
      </ul>
    </Text>
  </div>
)
