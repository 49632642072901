import React, { useLayoutEffect } from 'react'
import styles from './ContentWrapper.module.scss'

export const ContentWrapper = (props: { children: React.ReactNode }) => {
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, behavior: 'smooth' })
  }, [location.pathname])

  return <div className={styles.root}>{props.children}</div>
}
